import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";



function Terms() {
    return (
        <div className='terms'>
            <h3>Terms of Service: CoinBuilder.pro</h3> <br />
            <p>
                The  CoinBuilder.pro Token Generator provides a streamlined service for the automatic creation of ERC20 and BEP20 tokens. As the user, you—or any designated transferee—will retain full ownership and control over the tokens, along with all associated responsibilities regarding their use on the Ethereum blockchain.
            </p>
            <p>
                We do not hold, store, or control your tokens at any point. Additionally, we are not liable for their usage or any financial losses that may occur.

            </p>
            <p>
                All transactions involving your tokens are conducted through your MetaMask wallet, or any other cryptocurrency wallet connect, are independent third-party applications. We disclaim any responsibility related to the use of MetaMask, other browser compatible wallets, or desktop or mobile wallets, and any outcomes that may arise from its use.
            </p>
            <p>
                Please be aware that your Ethereum public address and its contents may be visible to others when using the Token Maker. We are not responsible for the interactions with MetaMask, the Ethereum network, the Binance Smart Chain, or any other third parties involved in token creation.
            </p>
            <p>
                We have no oversight over transactions made through MetaMask, the Ethereum network, or the Binance Smart Chain, or any of the other blockchains and layer 2 applications used in token generation and cannot cancel or refund any transactions. You are solely responsible for these transactions, as well as for securing your tokens and any associated fiat or cryptocurrency funds.
            </p>
            <p>
                When using the CoinBuilder.pro application to create tokens, please understand that all actions performed on blockchain networks like Ethereum or Binance Smart Chain are beyond our control and are irreversible.

            </p>
            <p>
                Cryptocurrency prices are inherently volatile, which could impact the value of your token. You are also responsible for any taxes or costs related to your token and the transactions made with it.
            </p>
            <p>
                We are not liable for losses related to hardware or software malfunctions, internet connectivity issues, cyberattacks, or unauthorized access to your wallet.
            </p>
            <p>
                Network changes or upgrades to the Ethereum network or Binance Smart Chain, Polygon, Avalanche, Fantom, Arbitrum, Optimism or other chains may affect your token; such changes are outside our control and responsibility.
            </p>
            <p>
                Network changes or upgrades to the Ethereum network or Binance Smart Chain, Polygon, Avalanche, Fantom, Arbitrum, Optimism or other chains may affect your token; such changes are outside our control and responsibility.
            </p>
            <p>
                It is crucial to be aware of and comply with all relevant laws and regulations in your country and jurisdiction and in any regions where you operate, concerning cryptocurrencies and blockchain technology. We disclaim any liability for non-compliance with these legal requirements.
            </p>
        </div>
    )
}

export default Terms
