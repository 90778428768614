import React from "react";
import { ChainIcon } from "connectkit";
import { useAccount, useNetwork, useSwitchChain, useChainId } from "wagmi";
import { toast } from "react-toastify";

function SwitchNetwork() {
  const chainId = useChainId();
  const { chains, error, isLoading, pendingChainId, switchChain } =
    useSwitchChain();
  const { address, isConnected } = useAccount();

  const handleChange = (e) => {
    if (address && isConnected) {
      switchChain({ chainId: Number(e.target.value) });
    } else {
      toast.error("You need to connect your wallet");
    }
  };
  return (
    <div
      className=" mb-5"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <select
        className="form-select"
        onChange={handleChange}
        disabled={!switchChain || !isConnected}
        value={chainId || 0}
      >
        {chains.map((e) => (
          <option value={e.id} name={e.name} key={e.id}>
            <ChainIcon id={Number(e.id)} />
            {e.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SwitchNetwork;
