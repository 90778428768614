import React from "react";
import { ConnectKitButton } from "connectkit";

function ConnectButton() {
  function truncateEthereumAddress(address) {
    const truncatedAddress =
      address.substring(0, 6) + "..." + address.substring(address.length - 4);
    return truncatedAddress;
  }
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName, chain }) => {
        return (
          <button className="btn-start" onClick={show}>
            {isConnected ? truncateEthereumAddress(address) : "Connect Wallet"}
          </button>
        );
      }}
    </ConnectKitButton.Custom>
  );
}

export default ConnectButton;
