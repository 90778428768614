import React from "react";
import { useState } from "react";

function Input({
  label,
  placeholder,
  required,
  onChange,
  name,
  value,
  disabled,
}) {
  const [focused, setFocused] = useState(false);
  return (
    <div className="col">
      {label && (
        <label>
          {label}
          <span style={{ color: "red" }}>{required && " *"}</span>
        </label>
      )}
      <input
        type="text"
        className="form-control"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        autoComplete="off"
        placeholder={placeholder && placeholder}
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

export default Input;
