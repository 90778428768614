import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./components/Main";
import Page from "./components/Page";
import Terms from "./components/Terms";
import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  mainnet,
  bsc,
  fantom,
  polygon,
  sepolia,
  base,
  optimism,
  arbitrum,
  avalanche,
} from "wagmi/chains";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ConnectKitButton } from "connectkit";
import ConnectButton from "./components/ConnectButton";
import SwitchNetwork from "./components/SwitchNetwork";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

export const config = createConfig({
  chains: [
    sepolia,
    mainnet,
    bsc,
    fantom,
    polygon,
    base,
    optimism,
    arbitrum,
    avalanche,
  ],
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: http(),
    [fantom.id]: http(),
    [polygon.id]: http(),
    [base.id]: http(),
    [optimism.id]: http(),
    [arbitrum.id]: http(),
    [avalanche.id]: http(),
  },
});
function App() {
  const conf = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "2y36ih4AWP8zYI9zAV-31vP-K8yZqQia", // or infuraId
      walletConnectProjectId: "4041a76118186c1b19d87994c0d8ff40",

      // Required
      appName: "Your App Name",

      // Optional
      chains: [
        sepolia,
        mainnet,
        bsc,
        fantom,
        polygon,
        base,
        optimism,
        arbitrum,
        avalanche,
      ],
      transports: {
        [mainnet.id]: http(),
        [bsc.id]: http(),
        [fantom.id]: http(),
        [polygon.id]: http(),
        [base.id]: http(),
        [optimism.id]: http(),
        [arbitrum.id]: http(),
        [avalanche.id]: http(),
      },
    })
  );

  const queryClient = new QueryClient();

  const Web3Provider = ({ children }) => {
    return (
      <WagmiProvider config={conf}>
        <QueryClientProvider client={queryClient}>
          <ConnectKitProvider>{children}</ConnectKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    );
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ fontSize: "14px", zIndex: "10" }}
      />
      <Web3Provider>
        <Router>
          {/* Menü veya Navigasyon Linkleri */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              justifyContent: "space-between",
              maxWidth: "100%",
            }}
          >
            <Navbar collapseOnSelect expand="md" className="p-3">
              <Navbar.Brand href="/">
                <img
                  src="logocoinbuilderpro.png"
                  alt=""
                  width="198"
                  height="36"
                  className="img-fluid"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Nav.Link href="/create-token" style={{ fontSize: "1.3rem" }}>
                    Create Token
                  </Nav.Link>
                  <ConnectButton />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/create-token" element={<Page />} />
              <Route path="/terms-of-service" element={<Terms />} />
            </Routes>
            <div>
              <div className="page-footer"> </div>
              <div className="footer">
                <div className="container">
                  <div className="row text-center">
                    <div className="col-md-4">
                      <h5>
                        <Link to="/">
                          <img
                            src="background.png"
                            alt=""
                            width="198"
                            height="36"
                            className="img-fluid"
                          />
                        </Link>
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <h5>
                        <Link to="/create-token" className="altcizgi">
                          Token Maker
                        </Link>
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <h5>
                        <Link to="/terms-of-service" className="altcizgi">
                          Terms of service
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      </Web3Provider>
    </>
  );
}

export default App;
