import React from "react";
import Spinner from "react-bootstrap/Spinner";

function PageTitle(props) {
  return (
    <section className="page-title">
      <div className="shape"></div>
      <div className="shape right s3"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title__body">
              <div className="page-title__main">
                <h5 className="title">
                  {props.statusText && props.statusText}
                </h5>
                {props.deploymentLoading && (
                  <div className="bottom">
                    <h5>{props.processName && props.processName}</h5>
                    <Spinner animation="border" size="xl" className="mt-3" />
                  </div>
                )}
                {props.deployedTokenAddress && (
                  <>
                    <h5>Contract Address</h5>
                    <p>
                      <a
                        href={
                          props.currentNetwork.blockExplorer +
                          "/token/" +
                          props.deployedTokenAddress
                        }
                        target="_blank"
                      >
                        {props.deployedTokenAddress}
                      </a>
                    </p>
                  </>
                )}

                {!props.deploymentLoading && (
                  <>
                    <a
                      className="action-btn mt-3"
                      href="/"
                      style={{ marginRight: "15px" }}
                    >
                      Back to home
                    </a>
                    <a className="action-btn mt-3" href="/createtoken">
                      Create New Token
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageTitle;
